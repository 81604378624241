import * as React from 'react';

class RegistrationSuccess extends React.Component {
    public render(): JSX.Element {
        return (
            <div className="container top-p-2">
                <div className="text-center content-header-block">
                    <i className="fa fa-check green"></i>
                    <h3>Done for now!</h3>
                </div>

                <p>
                    Thanks for signing up with Neosho Youth Wrestling. We are excited to kick-off another great season.
                    We have made several improvements to our wrestling club that we think will help make this year great!
                </p>

                <p>
                    <h4>Important Dates</h4>

                    <hr/>

                    <b>Parent Meeting</b>

                    <br/>
                    Date: November 12, 2024 at 6:00pm

                    <br/>
                    Carver Elementary School

                    <br/>
                    12350 Norway Rd

                    <br/>
                    Neosho MO, 64850

                    <br/>
                    <br/>
                    <b>First Practice </b>

                    <br/>
                    November 18, 2024

                    <br/>
                    <br/>
                    <b>Pictures</b>
                    <br/>
                    December 5, 2024
                    <br/>
                    Details at parent meeting

                </p>
            </div>
        );
    }
}

export default RegistrationSuccess;
